<!-- 代理合作 -->
<template>
  <div class="join">
    <img class="banner" src="@/assets/images/join/banner.jpg" alt="">
    <ul>
      <li class="first">
        <div class="w1200">
          <p class="title">为什么选择配友</p>
          <div class="item-p">
            <div class="item">
              <img src="@/assets/images/join/join01.png" alt="">
              <p class="desc">拥有专业的研发团队，资深产品经理，解决客户最核心需求，持续优化迭代产品</p>
            </div>
            <div class="item">
              <img src="@/assets/images/join/join02.png" alt="">
              <p class="desc">抢占垂直领域黄金赛道，汽配行业未来10-20年具备高速发展趋势</p>
            </div>
            <div class="item">
              <img src="@/assets/images/join/join03.png" alt="">
              <p class="desc">汽配行业数字化营销领先者，独创数据营销系统，打造获客、激活、转化业务闭环，企业刚需首选</p>
            </div>
          </div>
        </div>
      </li>
      <li class="second">
        <div class="w1200 clearfix">
          <p class="title">代理商扶持计划</p>
          <div class="fl one">
            <img src="@/assets/images/join/join04.png" alt="" />
          </div>
          <div class="fl two">
            <ul>
              <li>
                <div class="right-title">
                  <img src="@/assets/images/join/icon2.png" alt="">
                  <span>物料支持</span>
                </div>
                <div class="text">
                  产品单页、演示文稿、宣传视频等，配友为代理商提供完盖的物料及支持，深入了解配友产品
                </div>
              </li>
              <li>
                <div class="right-title">
                  <img src="@/assets/images/join/icon1.png" alt="">
                  <span>产品支持</span>
                </div>
                <div class="text">
                  独立的代理商系统，简单易操作，帮助代理商实现高效管理
                </div>
              </li>
              <li>
                <div class="right-title">
                  <img src="@/assets/images/join/icon3.png" alt="">
                  <span>培训支持</span>
                </div>
                <div class="text">
                  线上线下培训课程，销售全流程多维度专业培训，为代理商市场开拓赋能
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="third">
        <div class="w1200">
          <p class="title">申请成为代理商</p>
          <v-form ref="form" lazy-validation class="form" v-model="valid">
            <v-text-field v-model="formData.name" label="姓名" :rules="formRules.nameRule" required
              clearable></v-text-field>

            <v-text-field v-model="formData.company" label="公司名称" :rules="formRules.companyRule" required
              clearable></v-text-field>
            <v-text-field v-model="formData.tel" label="手机号" :rules="formRules.telRule" required clearable></v-text-field>
            <v-text-field v-model="formData.other" label="内容(选填)" clearable></v-text-field>

            <v-btn :disabled="!valid" color="#026ffd" class="sub-btn" @click="formSubmit">
              提交
            </v-btn>

          </v-form>
        </div>
      </li>
    </ul>

    <v-alert style="position: fixed;left: 50%;transform: translateX(-50%);top: 50px;z-index: 9991;"
      v-model="alertData.alertShow" :type="alertData.type">{{ alertData.msg }}</v-alert>

  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "代理合作-配友软件",
      // meta: [
      //   { charset: "utf-8" },
      //   { name: "description", content: "配友软件提供全方位的汽车配件行业数字营销解决方案，现对外招募合作代理商，为汽配商对接更多的用户提供集成化的销售管道。欢迎加入我们的代理队伍，为您赢得更多的客户与利润" },
      // ],
    };
  },
  data() {
    return {
      valid: true,
      formData: {
        name: '',
        company: '',
        tel: '',
        other: '',
      },
      formRules: {
        nameRule: [
          v => !!v || '姓名为必填',
        ],
        companyRule: [
          v => !!v || '公司名称为必填',
        ],
        telRule: [
          v => !!v || '手机号为必填',
          v => /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(v) || '手机号格式错误',
        ],
      },
      alertData: {
        alertShow: false,
        msg: '提交成功，客服将在3个工作日内与你联系',
        type: 'success',
      },
      btnLoading: false,
	  is_save: true,
    }
  },
  created(){
    document.querySelector('meta[name="description"]').setAttribute('content',"配友软件提供全方位的汽车配件行业数字营销解决方案，现对外招募合作代理商，为汽配商对接更多的用户提供集成化的销售管道。欢迎加入我们的代理队伍，为您赢得更多的客户与利润")
  },
  methods: {
    formSubmit() {
      if (this.$refs.form.validate()) {
		  if (this.is_save) {
			  this.btnLoading = true;
			  this.is_save = false;
			  this.$http({
			    url: this.$store.state.requestUrl+"/v1.1/apply_agent",
			    method: 'post',
			    data: {
			      company_name: this.formData.company,
			      name: this.formData.name,
			      mobile: this.formData.tel,
			      content: this.formData.other,
			    }
			  }).then(res => {
			    this.btnLoading = false;
				setTimeout((v) => {
					this.is_save = true;
				},3000)
			    if (res.data.code === 0) {
			      this.alertData = {
			        alertShow: true,
			        msg: '提交成功，客服将在3个工作日内与你联系',
			        type: 'success',
			      }
			    } else {
			      this.alertData = {
			        alertShow: true,
			        msg: res.data.msg,
			        type: 'error',
			      }
			    }
			    setTimeout(() => {
			      this.alertData.alertShow = false;
			      this.$refs.form.reset()
			    }, 1000);
			  }).catch(err => {
			    console.log(err);
			  
			  })
		  }
        
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.join {

  &>ul {
    padding: 0;
    width: 100%;

    &>li:not(:last-child) {
      background-color: #fff;
    }

    p.title {
      font-size: 32px !important;
      font-weight: 700;
      text-align: center;
      color: #333333;
      padding-top: 70px;
      margin-bottom: 90px;
    }
  }

  li.first {
    .item-p {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .item {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;


        img {
          width: 270px;
          height: 230px;
        }

        p.desc {
          margin-top: 60px;
          margin-bottom: 0;
          font-size: 18px;
          line-height: 36px;
          color: #333333;
        }
      }
    }
  }

  li.second {
    margin-bottom: 80px;

    .fl.one {
      width: 666px;
      height: 460px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .fl.two {
      ul {
        padding-left: 66px;

        li {
          width: 440px;

          &:not(:last-child) {
            margin-bottom: 50px;
          }

          .right-title {
            height: 28px;
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            img {
              width: 34px;
              height: 28px;
              margin-right: 16px;
            }

            span {
              font-size: 24px;
              line-height: 46px;
              color: #333333;
            }
          }

          div.text {
            font-size: 16px;
            line-height: 36px;
            color: #666666;
          }
        }
      }
    }
  }

  li.third {
    overflow: hidden;
    // background-color: #d4e3f9;
    background: url("../../assets/images/join/formbg.png") no-repeat center center;
    background-size: 1920px 100%;
    height: 567px;
    overflow: hidden;
    position: relative;

    .form {
      width: 520px;
      margin: -30px auto 40px;

      :deep .v-label {
        font-size: 18px;
      }

      .sub-btn {
        width: 140px;
        color: #fff;
        display: block;
        margin: 30px auto 0;
      }
    }
  }
}
</style>